import { useState } from "react"

import { SubscriptionStatus } from "src/components/Account/types"
import { NotEnoughPermissionsDialog } from "src/components/FeatureBlockers/NotEnoughPermissionsDialog"
import { ReactivateBlockerDialog } from "src/components/FeatureBlockers/ReactivateBlockerDialog"
import { StoreBlockerDialog } from "src/components/FeatureBlockers/StoreBlockerDialog"
import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { CreateHomeConfirmStep } from "src/components/Homes/CreateHomeWizard/CreateHomeConfirmStep"
import {
  CREATE_HOME_FORM_ID,
  EMPTY_AUTOCOMPLETE_ADDRESS,
} from "src/components/Homes/CreateHomeWizard/createHomeConstants"
import { SelectMonitoringPresetStep } from "src/components/Homes/CreateHomeWizard/SelectMonitoringPresetStep"
import {
  ISelectedOption,
  SelectSettingsProfileStep,
} from "src/components/Homes/CreateHomeWizard/SelectSettingsProfileStep"
import {
  HOME_ADDRESS_FORM_ID,
  HomeAddressStep,
} from "src/components/Homes/HomeAddress/HomeAddressStep"
import { HomeMapStep } from "src/components/Homes/HomeAddress/HomeMapStep"
import { isProdEnv } from "src/constants/env"
import { HREF_MINUT_STAGING_STORE, HREF_MINUT_STORE } from "src/constants/hrefs"
import {
  usePostCreateHomeConfirmed,
  usePostCreateHomeInitiated,
} from "src/data/analytics/queries/homeAnalyticsQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useBackendFlags } from "src/data/flags/useBackendFlags"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { TMaybeHomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { useFetchProfiles } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { TPostHome } from "src/data/homes/types/homeQueryTypes"
import { ILocation, THomeAddress } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchCreateHomeEstimate } from "src/data/organizations/queries/estimateQueries"
import { usePostHome } from "src/data/organizations/queries/homeQueries"
import { TSelectedPresetId } from "src/data/profileSettings/types/monitoringPresetTypes"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { INITIAL_CREATE_HOME_NAME_QUERY_KEY } from "src/router/routerQueryParams"
import { Routes } from "src/router/routes"
import { getSearchParam } from "src/router/searchParamsUtils"
import { useRouter } from "src/router/useRouter"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"
import { ErrorService } from "src/utils/ErrorService"
import { getLocalTimeZone, getTimeZoneFromLoc } from "src/utils/l10n"

import { StepId } from "./createHomeTypes"
import { HomeInformationStep } from "./HomeInformationStep"

export function CreateHomeWizard() {
  // #region Analytics
  const postCreateHomeInitiated = usePostCreateHomeInitiated()
  const postCreateHomeConfirmed = usePostCreateHomeConfirmed()
  // #endregion

  const { t, langKeys } = useTranslate()
  const { navigate, goBack } = useRouter()
  const beFlags = useBackendFlags()
  const presets = !!beFlags.noise_profiles_released

  const { orgId, org } = useOrganization()

  const createHomeAvailability = useFeatureAvailability({
    feature: "create_home",
    refresh: true,
  })

  const postHome = usePostHome()

  const [currentStep, setCurrentStep] = useState(0)
  const [homeGroup, setHomeGroup] = useState<TMaybeHomeGroup>(null)
  const [homeName, setHomeName] = useState(() => {
    const initialHomeName = getSearchParam({
      key: INITIAL_CREATE_HOME_NAME_QUERY_KEY,
      type: "string",
    })

    return initialHomeName || ""
  })
  const [error, setError] = useState(false)
  const [homeLocation, setHomeLocation] = useState<ILocation>({
    latitude: 0,
    longitude: 0,
  })
  const [homeAddress, setHomeAddress] = useState<THomeAddress>(
    EMPTY_AUTOCOMPLETE_ADDRESS
  )

  const [addressSearchString, setAddressSearchString] = useState("")

  const [selectedSettingsProfile, setSelectedSettingsProfile] =
    useState<ISelectedOption>({
      id: "",
      name: "",
    })
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [checkedTermsError, setCheckedTermsError] = useState(false)

  const isHomegroupAdmin = useIsHomeGroupAdmin()
  const permissions = getAccessLogic({ role: org.user_role })
  const hasCreateHomePermission =
    permissions.hasAdminAccess || isHomegroupAdmin.value

  const hasPlanUnitPerHome = org.billing_model === "plan_unit_per_home"

  const fetchCreateHomeEstimate = useFetchCreateHomeEstimate({
    orgId,
    quantity: 1,
    options: {
      enabled: hasCreateHomePermission && hasPlanUnitPerHome,
    },
  })

  const fetchProfiles = useFetchProfiles({
    orgId,
  })

  const profiles = fetchProfiles.data?.profiles || []

  const [selectedPresetId, setSelectedPresetId] = useState<TSelectedPresetId>({
    icm: "",
    noise: "",
  })

  const needsSubscription =
    createHomeAvailability.ready && !createHomeAvailability.available

  const needsReactivation =
    !needsSubscription &&
    fetchCreateHomeEstimate.error?.response?.data?.error_key ===
      "invalid_subscription_status"

  function handleHomeAddressStep(address: THomeAddress, location: ILocation) {
    setHomeLocation(location)
    setHomeAddress(address)
    setCurrentStep((currStep) => currStep + 1)
  }

  function handleOnEditClick(id: string) {
    const filteredSteps = stepper.filter((step) => !step.skip)
    const index = filteredSteps.findIndex((step) => step.id === id)

    // Prevent user from going to a blank step
    if (index < 0) {
      setError(true)
      return ErrorService.captureMessage(
        `Step with stepid: ${id} was not found`
      )
    }

    setCurrentStep(index)
  }

  async function handleCreateHome() {
    if (!checkedTerms && hasPlanUnitPerHome) {
      setCheckedTermsError(true)
      return
    }

    if (presets && !selectedPresetId.noise) {
      setError(true)
      return
    }

    postCreateHomeConfirmed.mutate()

    const body: TPostHome = {
      name: homeName,
      location: homeLocation,
      address: homeAddress,
      homegroup_id: homeGroup?.id ?? undefined,
      timezone: homeLocation
        ? getTimeZoneFromLoc(homeLocation.latitude, homeLocation.longitude)
        : getLocalTimeZone(),
      profile_id: selectedSettingsProfile.id || profiles[0]?.id,
    }

    const bodyWithPresetId: TPostHome = {
      name: homeName,
      location: homeLocation,
      address: homeAddress,
      homegroup_id: homeGroup?.id ?? undefined,
      timezone: homeLocation
        ? getTimeZoneFromLoc(homeLocation.latitude, homeLocation.longitude)
        : getLocalTimeZone(),
      noise_profile_id: selectedPresetId.noise,
    }

    try {
      const { home_id } = await postHome.mutateAsync({
        orgId,
        body: presets ? bodyWithPresetId : body,
      })

      return navigate(Routes.HomeSetup.location(home_id))
    } catch (error) {
      setError(true)
      ErrorService.captureException(error)
      return
    }
  }

  const stepper: IWizardStep[] = [
    {
      component: (
        <HomeInformationStep
          homeName={homeName}
          loading={fetchCreateHomeEstimate.isInitialLoading}
          error={fetchCreateHomeEstimate.isError}
          setError={setError}
          addressSearchString={addressSearchString}
          onSubmit={(name, group, address) => {
            if (group?.type === "building") {
              postCreateHomeInitiated.mutate({
                type: "building_selected",
              })

              navigate({
                pathname: Routes.HomeGroupBuildingCreateHome.location(group.id)
                  .pathname,
                search: `?${INITIAL_CREATE_HOME_NAME_QUERY_KEY}=${name}`,
              })
              return
            }

            postCreateHomeInitiated.mutate({
              type: !!group ? "location_selected" : "no_selection",
            })

            setHomeName(name)
            setHomeGroup(group)
            setHomeAddress(address.completedAddress)
            setAddressSearchString(address.searchString)
            setCurrentStep((step) => step + 1)
          }}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        disabled:
          !hasPlanUnitPerHome && org.subscription_quantity_limit_reached,
        form: CREATE_HOME_FORM_ID,
      },
      hideBackButton: true,
      onNext: () => {
        setCurrentStep((currStep) => currStep + 1)
      },
      footer: (
        <div>
          <MText>{t(langKeys.create_home_footer_text)}</MText>
          <InternalLink
            to={Routes.CreateHomeGroupBuilding.location()}
            color="primary"
          >
            {t(langKeys.create_home_footer_cta_label)}
          </InternalLink>
        </div>
      ),
    },
    {
      id: StepId.HomeAddressStep,
      component: (
        <HomeAddressStep
          onSuccess={handleHomeAddressStep}
          address={homeAddress}
          setupForGuardService={false}
        />
      ),
      nextButtonProps: {
        form: HOME_ADDRESS_FORM_ID,
      },
      nextButtonLabel: t(langKeys.continue),
    },
    {
      component: (
        <HomeMapStep
          onChangeLocation={setHomeLocation}
          location={homeLocation}
        />
      ),
      id: StepId.HomeMapStep,
      nextButtonLabel: t(langKeys.continue),
    },
    {
      component: (
        <SelectMonitoringPresetStep
          selectedPresetId={selectedPresetId}
          setSelectedPresetId={setSelectedPresetId}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        disabled: !selectedPresetId.noise,
      },
      skip: !presets,
    },
    {
      component: (
        <SelectSettingsProfileStep
          profiles={profiles}
          value={selectedSettingsProfile}
          onChange={setSelectedSettingsProfile}
        />
      ),
      id: StepId.SelectSettingsProfileStep,
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        disabled: !selectedSettingsProfile.id,
      },
      skip: profiles.length <= 1 || presets,
    },
    {
      component: (
        <CreateHomeConfirmStep
          location={homeLocation}
          address={homeAddress}
          settingsProfile={selectedSettingsProfile}
          handleOnEditClick={handleOnEditClick}
          createHomeEstimate={fetchCreateHomeEstimate.data}
          error={postHome.isError || error || fetchCreateHomeEstimate.isError}
          showSettingsProfileSection={profiles.length > 1 && !presets}
          checkedTerms={checkedTerms}
          onCheckTerms={() => {
            setCheckedTerms((prev) => !prev)
            setCheckedTermsError(false)
          }}
          checkedTermsError={checkedTermsError}
          hideBillingInformation={!hasPlanUnitPerHome}
        />
      ),
      nextButtonLabel: t(langKeys.create_home),
      border: false,
      onNext: handleCreateHome,
      nextButtonProps: {
        loading: postHome.isLoading || fetchCreateHomeEstimate.isInitialLoading,
        disabled:
          error || (!fetchCreateHomeEstimate.data && hasPlanUnitPerHome),
      },
      onBack: () => {
        setError(false)
        postHome.reset()
        setCurrentStep((currStep) => currStep - 1)
        setCheckedTermsError(false)
      },
    },
  ]

  if (needsSubscription) {
    return org.current_subscription_status !== SubscriptionStatus.CANCELLED ? (
      <StoreBlockerDialog
        open={true}
        onClose={() => goBack({ defaultPath: Routes.Homes.location() })}
        onConfirm={() => {
          window.location.href = isProdEnv
            ? HREF_MINUT_STORE
            : HREF_MINUT_STAGING_STORE
        }}
      />
    ) : (
      <UpgradeBlockerDialog
        context="subscription_required"
        open={true}
        onClose={() => goBack({ defaultPath: Routes.Homes.location() })}
      />
    )
  }

  if (needsReactivation) {
    return (
      <ReactivateBlockerDialog
        open={true}
        onClose={() => {
          goBack({ defaultPath: Routes.Homes.location() })
        }}
      />
    )
  }

  if (!hasCreateHomePermission) {
    return (
      <NotEnoughPermissionsDialog
        open={true}
        onClose={() => {
          goBack({ defaultPath: Routes.Homes.location() })
        }}
      />
    )
  }

  return (
    <FullscreenWizard
      title={t(langKeys.create_home)}
      steps={stepper}
      onClose={() => goBack({ defaultPath: Routes.Homes.location() })}
      currentStep={currentStep}
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}
